.logincontainer {
	overflow: hidden;
}

.wave {
	position: fixed;
	bottom: 0;
	left: 0;
	height: 100%;
	z-index: -1;
}

.login-container-form {
	width: 100vw;
	height: 100vh;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 7rem;
	padding: 0 2rem;
}

.login-form {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-left: 5%;
}

.login-form-inside {
	width: 60%;
	margin-bottom: 0px;
}

.login-form .input-container {
	position: relative;
	display: grid;
	grid-template-columns: 7% 93%;
	margin: 25px 0;
	padding: 5px 0;
}

.loginform-icon {
	color: #d9d9d9;
	display: flex;
	justify-content: center;
	align-items: center;
}

.user-icon {
	transition: .3s;
}

.input-container>div {
	position: relative;
	height: 45px;
}

.input-container>div>h5 {
	position: absolute;
	left: 10px;
	transform: translateY(-50%);
	color: #999;
	font-size: 15px;
	transition: .3s;
	margin-top: 20px;
}

.input-container:before,
.input-container:after {
	content: '';
	position: absolute;
	bottom: -2px;
	width: 0%;
	height: 2px;
	background-color: #1B4965;
	transition: .3s;
}

.input-container:before {
	right: 50%;
}

.input-container:after {
	left: 50%;
}

.input-container:focus-within:before,
.input-container:focus-within:after {
	width: 50%;
}

.input-container:focus-within>div>h5 {
	top: -20px;
	font-size: 15px;
	transition: .5s;
}

.input-container:focus-within>.loginform-icon>.user-icon {
	color: #1B4965;
}

.input-container>div>input {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	border: none;
	outline: none;
	background: none;
	padding: 0.5rem 0.7rem;
	font-size: 1.2rem;
	color: #555;
	font-family: 'poppins', sans-serif;
}

.input-container.pass {
	margin-bottom: 4px;
}

.forgotpassword {
	display: block;
	text-align: right;
	text-decoration: none;
	color: #999;
	font-size: 1rem;
	transition: .3s;
}

.forgotpassword:hover {
	color: #051925;
}

.loginotp {
	display: block;
	text-align: left;
	text-decoration: none;
	color: #999;
	font-size: 1rem;
	transition: .3s;
}

.loginotp:hover {
	color: #051925;
}

.loginpassword {
	display: block;
	text-align: left;
	text-decoration: none;
	color: #999;
	font-size: 1rem;
	transition: .3s;
}

.loginpassword:hover {
	color: #051925;
}

.loginpassword::before {
	content: "\2190";
	font-size: 20px;
}

.login-submit {
	display: block;
	width: 100%;
	height: 50px;
	border-radius: 25px;
	outline: none;
	border: none;
	background: linear-gradient(90deg, #1B4965 63.83%, #051925 82.97%);
	background-size: 200%;
	font-size: 1.2rem;
	color: #fff;
	font-family: 'Poppins', sans-serif;
	text-transform: uppercase;
	margin: 1rem 0;
	cursor: pointer;
	transition: .5s;
}

.login-submit:hover {
	background-position: right;
}

.input-active {
	top: -20px;
	position: absolute;
	transition: .3s;
}

.company-dropdown {
	width: 100%;
	height: 40px;
	border: none;
	outline: none;
	padding: 7px;
	color: #999;
	cursor: pointer;
	font-size: 15px;
	transition: .3s;
	height: 40px;
}

option {
	font-size: 13px;
	cursor: pointer;
	color: #051925;
}

.otp-card {
	text-align: center;
	padding: 2rem;
}

.otp-card-inputs {
	margin: 20px 0;
	display: grid;
	gap: 20px;
	justify-content: center;
	grid-template-columns: repeat(5, auto);
}

.otp-card-inputs input {
	width: 40px;
	height: 45px;
	font-size: 25px;
	text-align: center;
	border-radius: 15px;
	border: 1px solid black;
}

.otp-card-inputs input:focus {
	outline: 2px solid #1B4965;
	border-color: transparent;
}

.user-icon-active {
	color: #1B4965;
	transition: 0.3s;
}

.login-img img {
	height: 85%;
	margin-left: 15%;
}

.login-form-container {
	width: 70%;
	min-width: 300px;
}

@media screen and (max-width: 1240px)and (min-width: 980px) {
	.login-img img {
		height: 65%;
	}

	.login-form {
		margin-left: -100px;
	}
}

@media screen and (max-width: 979px)and (min-width: 850px) {
	.login-img img {
		height: 55%;
	}

	.login-form {
		margin-left: -100px;
	}

	.login-form-container {
		width: 505;
	}
}

@media screen and (max-width: 849px) {
	.login-img img {
		display: none;
	}

	.wave {
		display: none;
	}

	.login-container-form {
		background: #D5D7D8;
	}

	.login-form-container {
		margin-left: -500px;
	}
}