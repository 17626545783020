.custom-scrollbar::-webkit-scrollbar {
    width: 8px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: #1B4965;
    border-radius: 20px;
}

.custom-scrollbar::-webkit-scrollbar-track {
    background: #f0f0f0;
}
