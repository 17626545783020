.custom-pagination {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.custom-pagination .MuiIconButton-root {
  color: #1B4965;
  font-size: 14px;
}

.custom-pagination .rows-per-page {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.custom-pagination select {
  margin-left: 8px;
  border-radius: 5px;
  padding: 5px;
}

.rows-per-page {
  margin-right: 15px;
  text-align: center;
  font-size: 14px;
}