.sysadmin-dashboard {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.sysadmin-title {
    margin-left: 30px;
}

.sysadmin-title h1 {
    color: #1B4965;
    font-size: 2rem;
}

.sysadmin-addcompany {
    margin-right: 30px;
    background: #1B4965;
    color: white;
    width: 130px;
    height: 35px;
    text-align: center;
    font-size: 14px;
    border-radius: 5px;
    cursor: pointer;
}

.sysadmin-companytable {
    width: 95%;
    margin: 5px 30px;
}

.companylist-table {
    width: 90%;
    border-collapse: collapse;
    border-radius: 15px;
}

.companylist-table th {
    background: #1B4965;
    color: white;
    height: 40px;
}

.companylist-table th:first-child {
    border-top-left-radius: 15px;
}

.companylist-table th:last-child {
    border-top-right-radius: 15px;
}

.companylist-table tr:last-child {
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
}

.table-header-span {
    display: flex;
    justify-content: center;
    border-right: 1px solid white;
}

.companylist-table td {
    text-align: center;
    height: 35px;
    cursor: pointer;
    font-size: 13px;
    margin-bottom: 10px;
}

.companylist-table tr:nth-child(even) {
    background-color: #d6e1ed;
}

.companylist-table tr:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 10px;
    padding: 10px;
}

.companylist-table tr:last-child td:first-child {
    border-bottom-left-radius: 15px;
}

.companylist-table tr:last-child td:last-child {
    border-bottom-right-radius: 15px;
}

.pagination {
    text-align: right;
    float: right;
    display: flex;
    align-items: center;
    margin-right: 30px;
}