.departments-dashboard {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.departments-title {
    margin-left: 30px;
}

.departments-title p {
    color: #1B4965;
    font-size: 25px;
    font-weight: 600;
}

.add-department {
    margin-right: 30px;
    background: #1B4965;
    color: white;
    width: 130px;
    height: 35px;
    text-align: center;
    font-size: 14px;
    border-radius: 5px;
    cursor: pointer;
}

.departmentstable {
    width: 95%;
    margin: 5px 30px;
}

.muiTableBodyCell {
    font-size: 13px;
}

.muiTableHeadCell {
    position: sticky;
    top: 0;
    z-index: 1;
}

.muiTableFooter {
    position: sticky;
    bottom: 0;
    z-index: 1;
}