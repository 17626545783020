.forgot-password {
    background: #fff;
    width: 40%;
    max-width: 100%;
    box-sizing: border-box;
    padding: 25px;
    position: relative;
    z-index: 1;
    border-top: 5px solid #1B4965;
    -webkit-box-shadow: 0 0 3px rgb(0, 0, 0, 0.1);
    -moz-box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    -webkit-transition: none;
    transition: none;
    -webkit-animation: expand 0.8s 0.6s ease-out forwards;
    animation: expand 0.8s 0.6s ease-out forwards;
}

.forgotpassword-title-container h2 {
    font-weight: 1.5em;
    line-height: 1.5em;
    margin: 0;
}

.forgotpassword-title-container {
    text-align: center;
    padding-bottom: 15px;
}

.forgotpassword-box {
    margin: 10px -15px;
}

.forgotpassword-box>div {
    padding: 0 15px;
    box-sizing: border-box;
}

.input-field {
    position: relative;
    margin-bottom: 20px;
    -webkit-animation: bounce 0.6s ease-out;
    animation: bounce 0.6s ease-out;
}

.input-field>span {
    position: absolute;
    left: 0;
    top: 0;
    color: #333;
    height: 100%;
    border-right: 1px solid #cccccc;
    text-align: center;
    width: 50px;
}

.input-field>span>.email-icon {
    padding-top: 10px;
}

.forgot-password input[type=email] {
    width: 100%;
    padding: 8px 10px 9px 55px;
    height: 45px;
    border: 1px solid #cccccc;
    box-sizing: border-box;
    outline: none;
    transition: all 0.3s ease-in-out;
    border-radius: 5px;
    font-size: 20px;
}

.forgot-password input[type=email]:hover {
    background: #fafafa;
}

.forgot-password input[type=email]:focus {
    box-shadow: 0 0 2px 1px #051925;
    border: 1px solid #1B4965;
    background: #fafafa;
}

.forgot-password input[type=submit] {
    background: linear-gradient(90deg, #1B4965 63.83%, #051925 82.97%);
    background-size: 200%;
    height: 45px;
    line-height: 35px;
    width: 100%;
    border: none;
    outline: none;
    cursor: pointer;
    color: #fff;
    font-size: 1.1em;
    margin-bottom: 10px;
    transition: all 0.3s ease-in-out;
    border-radius: 5px;
}

.forgot-password input[type=submit]:hover {
    background: linear-gradient(90deg, #1B4965 63.83%, #051925 82.97%);
}