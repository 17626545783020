.fc {
    font-family: 'Arial', sans-serif;
    background-color: white;
    border-radius: 12px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.fc-toolbar {
    margin-bottom: 20px;
    background-color: #1B4965;
    border-radius: 8px;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
}

.fc-toolbar h2 {
    font-size: 1.8rem;
    margin: 0;
}

.fc-toolbar .fc-button {
    background-color: #1B4965;
    border: 1px solid #F5F5F5;
    color: white;
    border-radius: 4px;
    padding: 8px 12px;
    font-size: 0.9rem;
    transition: background-color 0.3s;
}

.fc-toolbar .fc-button:hover {
    background-color: #1B4965;
}

.fc-daygrid-day {
    border: 1px solid red;
    padding: 10px;
    transition: background-color 0.2s, transform 0.2s;
}

.fc-daygrid-day:hover {
    background-color: #F5F5F5;
    transform: scale(1.02);
}

.fc-daygrid-day.fc-day-today {
    background-color: #1B4965;
    color: white;
    border-radius: 4px;
}

.fc-timegrid-slot {
    border-bottom: 1px solid #ddd;
    padding: 5px;
    color: #333;
}

.fc-timegrid-slot.fc-timegrid-slot-lane {
    font-size: 0.8rem;
    font-weight: bold;
}

.fc-event {
    background-color: #1B4965;
    color: white;
    border-radius: 4px;
    padding: 4px 6px;
    font-size: 0.85rem;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
    transition: transform 0.2s;
}

.fc-event:hover {
    transform: scale(1.05);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.fc-timegrid-body {
    overflow-y: auto;
    max-height: 400px;
}

.fc-daygrid-event {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.fc-scrollgrid {
    border-radius: 8px;
    overflow: hidden;
}

.fc-scrollgrid-section {
    background-color: white;
}