.departmentRegistration{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #ffffff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    padding: 16px;
    border-radius: 8px;
}

#modal-modal-title{
    color: #1B4965;
    font-family: "Istok Web";
}