.main-container {
    height: 85vh;
    overflow-y: auto;
    overflow-x: hidden;
}

.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 15px;
}

.header-title {
    font-size: 20px;
    font-weight: bold;
    color: #1B4965;
}

.apply-button {
    background-color: #1B4965;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
}

.apply-button:hover {
    background-color: #1E6091;
}

.statistics-container {
    display: flex;
    gap: 16px;
    padding: 16px;
    border-radius: 8px;
    margin: 0px 0px;
}

.stat-card {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 10px 20px;
    border: 1px solid #ffffff;
    border-radius: 8px;
    background-color: #ffffff;
}

.stat-remaining {
    font-size: 14px;
    color: #6c757d;
    margin: 0;
}

.progress-wrapper {
    width: 80px;
    height: 80px;
    margin-bottom: 12px;
}

.stat-details {
    margin: 0 0 0 0;
    font-size: 14px;
    color: #666666;
}

.main-content-container {
    display: flex;
    gap: 16px;
    padding: 16px;
}

.leave-approval {
    flex: 2;
    background-color: #ffffff;
    border: 1px solid #ffffff;
    border-radius: 8px;
    padding: 5px;
}

.section-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 16px;
    color: #1B4965;
}

.approval-table {
    width: 100%;
    border-collapse: collapse;
}

.approval-table th,
.approval-table td {
    border-bottom: 1px solid #f1f1f1;
    text-align: left;
    padding: 8px;
}

.approval-table th {
    color: #6c757d;
}

.approval-table td {
    font-size: 14px;
}

.table-action-button {
    color: #ffffff;
    border: none;
    padding: 4px 8px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
}

.leave-track {
    flex: 1;
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 16px;
}

.leave-chart {
    height: 200px;
    background-color: #f8f9fa;
    border: 1px dashed #e0e0e0;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #6c757d;
    font-size: 14px;
}