.profile-meeting {
    margin-top: 20px;
    width: 240px;
    z-index: 99996;
}

.profile-meeting-container {
    background-color: #fff;
    padding: 10px;
    color: #1B4965;
    border: 1px solid #fff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    text-align: left;
}

.profile-meeting-container h2 {
    font-size: 14px;
    text-align: center;
}

.meeting-option {
    display: flex;
    flex-direction: column;
    padding: 0px 0;
    cursor: pointer;
    margin-bottom: -10px;
}

.meeting-option:last-child {
    border-bottom: none;
}

.meeting-time {
    font-weight: bold;
    margin-bottom: 2px;
    font-size: 14px;
}

.meeting-until {
    color: #666;
    margin-top: 0;
    font-size: 12px;
}