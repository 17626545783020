.fixedsidebar{
    position: fixed;
    background: linear-gradient(180deg, #1B4965 63.83%, #051925 82.97%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    width: 60px;
    height: calc(100% - 60px);
    top: 60px;
    z-index: 99;
}
.fixedsidebar-icons{
    margin-top: 20px;
    text-align: center;
}
.message-icon,
.groupchat-icon,
.notes-icon,
.email-icon{
    width: 26px;
    height: 26px;
}