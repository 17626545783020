.companyregistration{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #ffffff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    padding: 16px;
    border-radius: 8px;
}

#modal-modal-title{
    color: #1B4965;
    font-family: "Istok Web";
}

.TextField.Mui-focused{
    color: red;
}

.company-logo-upload{
    height: 120px;
    text-align: center;
    border: 1px dashed #1B4965;
    border-radius: 10px;
    cursor: pointer;
    display: 'flex';
    flex-direction: 'column';
    align-items: center;
    justify-content: center;
}

.company-logo-upload p{
    margin: auto;
}

.company-logo-upload img{
    margin: auto;
    margin-top: 15px;
}

.admin-photo-upload {
    height: 150px;
    border: 1px dashed #1B4965;
    border-radius: 10px;
    cursor: pointer;
    width: 90%;
    margin-left: 15px;
    text-align: center;
}

.admin-photo-upload img {
    margin: auto;
    margin-top: 30px;
}

.company-documents-upload {
    height: 120px;
    border: 1px dashed #1B4965;
    border-radius: 10px;
    cursor: pointer;
    width: 99%;
    text-align: center;
}

.company-documents-upload img {
    margin: auto;
    margin-top: 20px;
}