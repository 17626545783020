.profile-details {
    position: absolute;
    right: 1%;
    margin-top: 65px;
    width: 320px;
    z-index: 99995;
}

.profile-details-container {
    background-color: #346685;
    padding: 10px;
    font-size: 13px;
    border-radius: 10px;
    color: #ffffff;
    border: 1px solid #1B4965;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    text-align: center;
}

.edit-details {
    width: 70%;
    padding: 7px 15px;
    border-radius: 10px;
    border: none;
    outline: none;
    cursor: pointer;
    color: #1B4965;
}

.edit-details:hover {
    background-color: rgb(170, 190, 212);
}