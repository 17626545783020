.navbar {
    height: 60px;
    width: 100%;
    background: linear-gradient(90deg, #1B4965 63.83%, #051925 82.97%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    position: fixed;
    z-index: 1000;
}

.nav-comp1 {
    display: flex;
    flex: 2;
    gap: 60px;
}

.nav-logo {
    margin-left: 5px;
    cursor: pointer;
}

.app-logo {
    width: auto;
    height: 60px;
    object-fit: fill;
}

.nav-comp2 {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    font-size: 13px;
}

.nav-active {
    box-sizing: border-box;
    width: fit-content;
    height: 35px;
    margin-top: 13px;
    color: #fff;
    border: 1px solid #fff;
    border-radius: 40px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    cursor: pointer;
    padding: 0 10px;
    white-space: nowrap;
    margin-right: 15px;
}

.active-color {
    content: '';
    height: 13px;
    width: 13px;
    background-color: green;
    border-radius: 50px;
    margin-right: 5px;
}

.away {
    background-color: #ccc;
    border: 2px solid white;
    border-radius: 50%;
    width: 11px;
    height: 11px;
}

.meeting,
.offline,
.newstatus {
    content: '';
    height: 13px;
    width: 13px;
    border-radius: 50px;
}

.meeting {
    background-color: blue;
}

.offline {
    background-color: red;
}

.newstatus {
    background-color: yellow;
}

.downarrow {
    margin-top: 8px;
    height: 15px;
    cursor: pointer;
    margin-left: 5px;
}

.menu-icon,
.logout-icon,
.settings-icon,
.notification-icon,
.questions-icon {
    margin: 20px 12px;
    height: 20px;
    cursor: pointer;
}

.vertical-line {
    height: 30%;
    margin: 0px 0px;
    margin-top: 20px;
    border: 1px solid #CCCCCC;
}

.profile {
    min-width: 150px;
    height: 40px;
    border: 1px solid #CCCCCC;
    border-radius: 10px;
    margin: 10px 10px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    cursor: pointer;
}

.user-img {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    object-fit: fill;
}

.user-name {
    color: #CCCCCC;
    font-size: 13px;
    white-space: nowrap;
    white-space: nowrap;
}

.profiledownarrow {
    margin-top: 5px;
    height: 15px;
    cursor: pointer;
}

.profile-active {
    margin-top: 20px;
    width: 240px;
    font-size: 13px;
    z-index: 99997;
}

.profile-active-container {
    background-color: #fff;
    padding: 10px;
    color: #1B4965;
    border: 1px solid #fff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    text-align: left;
}

.profile-active-automatic,
.profile-active-meeting,
.profile-active-away,
.profile-active-add-status {
    display: flex;
    align-items: center;
    padding: 10px 5px;
    cursor: pointer;
}

.profile-active-color,
.profile-active-color-meeting,
.profile-active-color-away,
.profile-active-color-status,
.profile-active-addstatus {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 10px;
}

.profile-active-color {
    background-color: green;
}

.profile-active-color-meeting {
    background-color: blue;
}

.profile-active-color-away {
    background-color: transparent;
    border: 2px solid black;
    border-radius: 50%;
    width: 11px;
    height: 11px;
}

.profile-active-color-status,
.profile-active-addstatus {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15px;
    height: 15px;
}

.profile-active-content {
    flex-grow: 1;
}

.profile-active-content .title {
    margin: 0;
    font-weight: bold;
}

.profile-active-content .subtitle {
    margin: 0;
    color: grey;
    font-size: 0.9em;
}

.profile-activity-check {
    color: grey;
}

.profile-active-meeting,
.profile-active-away,
.profile-active-add-status {
    margin-top: 10px;
}

.profile-active-add-status .profile-active-color-status {
    margin-right: 10px;
}

.profile-active-add-status {
    border-top: 1px solid #ddd;
    padding-top: 10px;
}

.profile-active-color-status {
    content: '';
    height: 12px;
    width: 12px;
    background-color: red;
    border-radius: 50px;
}

@media screen and (max-width: 935px) and (min-width: 768px) {
    .app-logo {
        width: 150px;
        height: 55px;
        margin-top: 3px;
    }

    .nav-search {
        width: 35%;
        min-width: 100px;
        margin-left: 10px;
    }

    .search-icon {
        margin-left: 10px;
        height: 15px;
    }

    .nav-active {
        width: 20%;
    }

    .active-color {
        height: 10px;
        width: 10px;
    }

    .downarrow {
        height: 13px;
    }

    .nav-text {
        font-size: 13px;
    }

    .menu-icon,
    .logout-icon,
    .settings-icon,
    .notification-icon,
    .questions-icon {
        margin: 20px 12px;
        height: 22px;
    }

    .vertical-line {
        height: 25%;
    }

    .profile {
        width: 130px;
        height: 30px;
        margin-top: 15px;
    }

    .user-img {
        height: 25px;
        width: 25px;
    }

    .user-name {
        font-size: 13px;
    }

    .profiledownarrow {
        height: 13px;
    }
}