.addStatus-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 450px;
    background: white;
    border-radius: 8px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    padding: 20px;
    outline: none;
}

.addStatus-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.predefined-statuses {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 20px 0;
    max-height: 350px;
    overflow-y: auto;
}

.addStatus-option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
}

.addStatus-option.selected {
    background: #5c788a;
    color: #fff;
}

.addStatus-icon {
    font-size: 18px;
}

.addStatus-label {
    font-size: 14px;
}

.addStatus-time {
    font-size: 14px;
}

.addStatus-modal-actions {
    display: flex;
    justify-content: space-between;
}