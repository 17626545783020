.swal-container {
    z-index: 99998;
}

::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    background: #f0f0f0;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background: #1B4965; 
    border-radius: 10px; 
}

::-webkit-scrollbar-thumb:hover {
    background: #1B6C9C; 
}

* {
    scrollbar-width: thin; 
    scrollbar-color: #1B4965 #f0f0f0;
}
