.changePassword {
    display: flex;
    min-height: 100vh;
    align-items: center;
    justify-content: center;
    background: #f9f9f9;
    font-family: 'Open Sans', sans-serif;
}

.changepassword-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 500px;
    border-color: white;
    padding: 36px 0;
    border-radius: 4px;
    margin: 36px 0;
    box-shadow: 0px 0 2px 0 rgba(0, 0, 0, 0.25);
}

.changepassword-logo {
    max-height: 100px;
    margin: auto;
    display: flex;
    flex-direction: column;
}

.changepassword-title {
    font-weight: 600;
    margin-top: 20px;
    color: #2F2D3B;
    text-align: center;
}

.otp-card-inputs {
    margin: 20px 0;
    display: grid;
    gap: 20px;
    justify-content: center;
    grid-template-columns: repeat(6, auto);
    background-color: 'red';
}

.otp-card-inputs input {
    width: 40px;
    height: 45px;
    font-size: 25px;
    text-align: center;
    border-radius: 15px;
    border: 1px solid black;
}

.otp-card-inputs input:focus {
    outline: 2px solid #1B4965;
    border-color: transparent;
}