.notificationbar {
    position: relative;
    background: #EDF6FF;
    display: flex;
    align-items: center;
    padding: 5px;
    z-index: -1;
    box-shadow: 1px 1px 1px 1px #D9D9D9;
    font-size: 13px;
}

.scroll-text {
    display: inline-block;
    white-space: nowrap;
    animation: scroll-left 10s linear infinite;
    font-size: 12px;
    color: red;
  }
  
  @keyframes scroll-left {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(-100%);
    }
  }