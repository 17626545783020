.dynamicsidebar {
    background: #F1F1F1;
    border-right: 1px solid rgba(0, 0, 0, 0.25);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    height: 100%;
    font-size: 13px;
    margin-left: 60px;
    display: flex;
    flex-direction: column;
}

.dymanicsidebar-icons {
    display: flex;
    align-items: center;
    font-weight: 500;
    margin: 0px 0px;
    padding: 5px 0px;
    cursor: pointer;
}

.dymanicsidebar-icons span {
    margin-top: 5px;
    font-size: 13px;
}

.sidebar-icon {
    height: 28px;
    width: 28px;
    margin: 5px 15px;
    margin-right: 10px;
}

.arrow-container {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    height: 100%;
    flex: 1;
}

.rightarrow,
.leftarrow {
    cursor: pointer;
    width: 35px;
    height: 35px;
    margin-right: 15px;
}

.dymanicsidebar-icons:hover {
    background-color: #1B4965;
    color: white;
    transition: 0.2s ease-in-out;
}

.dymanicsidebar-icons.active {
    background-color: #1B4965;
    color: white;
}